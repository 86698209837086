// @see https://github.com/mdn/samples-server/blob/master/s/webrtc-capturestill/capture.js
// The width and height of the captured photo. We will set the
// width to the value defined here, but the height will be
// calculated based on the aspect ratio of the input stream.

const width  = 600    // We will scale the photo width to this
const height = 0     // This will be computed based on the input stream

export default {
  data() {
    return {
      streaming: false,
      stream: null,
      permitted: true
    }
  },

  mounted() {
    this.$refs.video.addEventListener('canplay', (event) => {
      if (!this.streaming) {
        let height = this.$refs.video.videoHeight / (this.$refs.video.videoWidth/width)

        // Firefox currently has a bug where the height can't be read from
        // the video, so we will make assumptions if this happens.

        if (isNaN(height)) {
          height = width / (4/3)
        }

        this.$refs.video.setAttribute('width', width)
        this.$refs.video.setAttribute('height', height)
        this.$refs.canvas.setAttribute('width', width)
        this.$refs.canvas.setAttribute('height', height)

        this.streaming = true
      }
    }, false)
  },

  methods: {
    enable() {
      navigator.mediaDevices.getUserMedia({video: true, audio: false})
          .then((stream) => {
            this.stream = stream
            this.$refs.video.srcObject = stream
            this.$refs.video.play()
          })
          .catch(() => {
            this.permitted = false
          })
    },

    disable() {
      if (this.stream) {
        let track = this.stream.getTracks()[0]
        track.stop()
      }
    },

    takePicture() {
      let context = this.$refs.canvas.getContext('2d')
      context.drawImage(this.$refs.video, 0, 0, this.$refs.canvas.width, this.$refs.canvas.height)

      let picture = this.$refs.canvas.toDataURL('image/png')

      this.$emit('pictureTaken', picture)
    },

    goBack() {
      this.$emit('back')
      this.disable()
    }
  },

  beforeDestroy() {
    this.disable()
  }
}