<template>
  <div class="avatar-uploader-form">
    <intro v-if="showIntro"
           :showWebCamButton="showWebCamButton"
           @fileSelected="onFileSelected"
           @webCamRequested="openWebCam"/>

    <web-cam ref="webCam"
             @back="openIntro"
             @pictureTaken="onWebCamPictureTaken"
             v-show="showWebCam"/>

    <cropper ref="cropper"
             :avatar="avatar"
             class="cropper"
             v-if="showCropper"/>

    <div class="bottom-panel"
         v-show="showCropper">
      <div class="button-back"
           @click="openIntro">
        {{ $t('shared.avatarUploader.back') }}
      </div>
      <base-button size="small"
                   @click="upload"
                   :loading="loading">
        {{ $t('shared.avatarUploader.save') }}
      </base-button>
    </div>
  </div>
</template>

<script>
  import Intro from './Intro/Intro.vue'
  import WebCam from './WebCam/WebCam.vue'
  import Cropper from './Cropper/Cropper.vue'

  export default {
    components: {
      Intro,
      WebCam,
      Cropper
    },

    props: {
      user: {
        required: false,
        type: Object
      }
    },

    data() {
      return {
        showIntro: true,
        showCropper: false,
        showWebCam: false,
        avatar: null,
        loading: false,
        cameraAvailable: false
      }
    },

    created() {
      this.checkCameraAvailability().then(cameraAvailable => {
        this.cameraAvailable = cameraAvailable
      })
    },

    methods: {
      upload() {
        this.loading = true
        let formData = new FormData
        formData.append('image', this.$refs.cropper.getImageBlob())

        if (!this.user) {
          this.loading = false
          this.$emit('avatarUploaded', this.$refs.cropper.getCroppedImage(), formData)

          return
        }

        axios.post(`/profiles/${this.user.user_id}/avatar`, formData).then((response) => {
          this.loading = false
          this.$emit('avatarUploaded')
        })
      },

      onFileSelected(picture) {
        this.avatar = picture
        this.openCropper()
      },

      openWebCam() {
        this.hideEverything()
        this.showWebCam = true

        this.$refs.webCam.enable()
      },

      onWebCamPictureTaken(picture) {
        this.avatar = picture
        this.openCropper()
      },

      hideEverything() {
        this.showIntro   = false
        this.showCropper = false
        this.showWebCam  = false
      },

      openIntro() {
        this.hideEverything()
        this.showIntro = true
      },

      openCropper() {
        this.hideEverything()
        this.$refs.webCam.disable()
        this.showCropper = true
      },

      checkCameraAvailability() {
        return navigator.mediaDevices.enumerateDevices().then(devices => {
          return devices.some(device => device.kind === 'videoinput')
        })
      }
    },

    computed: {
      showWebCamButton() {
        return this.cameraAvailable && !!this.user && !this.user.isClient
      }
    },

    watch: {
      show() {
        this.reset()
      },
    }
  }
</script>

<style lang="scss" scoped>
  .avatar-uploader-form {
    display: flex;
    flex-direction: column;
    height: 100%;

    .cropper {
      height: 80%;
      margin-bottom: 20px;
    }

    .bottom-panel {
      display: flex;
      justify-content: space-between;
      margin-top: auto;

      .button-back {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #797979;
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
</style>