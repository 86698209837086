import VueCropper from 'vue-cropperjs'
import dataURLtoBlob from 'blueimp-canvas-to-blob'

export default {
  components: {
    VueCropper
  },

  props: {
    avatar: {
      required: true
    }
  },

  methods: {
    getImageBlob() {
      return dataURLtoBlob(this.getCroppedImage())
    },

    getCroppedImage() {
      return this.$refs.cropper.getCroppedCanvas().toDataURL('image/png')
    },

    replace: function(src) {
      this.$refs.cropper.replace(src)
    }
  },

  watch: {
    avatar: function() {
      this.replace()
    }
  }
}