export default {
  props: {
    showWebCamButton: {
      required: false,
      type: Boolean,
      default: true
    }
  },

  mounted() {
    this.listenToFileChange()
  },

  methods: {
    listenToFileChange() {
      this.$refs.input.addEventListener('change', (event) => {
        let files = event.currentTarget.files

        if (files && files[0]) {
          let reader = new FileReader()

          reader.onload = (event) => {
            this.$emit('fileSelected', event.target.result)
          }

          reader.readAsDataURL(files[0])
        }
      })
    },

    onWebCamClick() {
      this.$emit('webCamRequested')
    }
  }
}